import axios from 'axios'
import { useEffect, useState } from 'react'

import useCmsHome from './useCmsHome'
import { useClubContext } from '../local_modules/contexts/ClubContext'
import { useSession } from '../local_modules/sdk/session'

import type { DynamicShowcase } from '../local_modules/components/HomePage/DynamicShowcases'
import type { ProductsData } from '../local_modules/types/RepeatOrder'

interface Category {
  name: string
  products: Product[]
}

interface Product {
  sku: string
  isRecommendation: boolean
}

export const useDynamicShowcases = () => {
  const [loading, setLoading] = useState(false)
  const [showcases, setShowcases] = useState<Category[]>([])
  const [dynamicShowcases, setDynamicShowcases] = useState<DynamicShowcase[]>(
    []
  )

  const { sections } = useCmsHome()
  const { channel } = useSession()
  const { isClubClient } = useClubContext()

  useEffect(() => {
    const isActive: boolean | undefined = sections?.find(
      ({ name }) => name === 'DynamicShowcases'
    )?.data?.isActive

    if (!isActive) {
      return
    }

    const fetchShowcases = async () => {
      if (!isClubClient) return

      try {
        setLoading(true)

        const { data } = await axios.get<Category[]>('/api/loyalty/showcases')

        setShowcases(data)
      } catch {
        setShowcases([])
      } finally {
        setLoading(false)
      }
    }

    fetchShowcases()
  }, [])

  useEffect(() => {
    if (!showcases.length) {
      return
    }

    const fetchProductsDetails = async () => {
      try {
        setLoading(true)

        const ids = showcases.flatMap(({ products }) =>
          products.map(({ sku }) => sku)
        )

        const {
          data: { products: productsDetailsList },
        } = await axios.post<ProductsData>('/api/products/getProductsByIds', {
          ids,
          channel,
        })

        const showcasesWithProductsDetails = mapperDynamicShowcases(
          showcases,
          productsDetailsList
        )

        setDynamicShowcases(showcasesWithProductsDetails)
      } catch {
        setShowcases([])
      } finally {
        setLoading(false)
      }
    }

    fetchProductsDetails()
  }, [JSON.stringify(showcases), channel])

  return { loading, dynamicShowcases }
}

function mapperDynamicShowcases(
  showcases: Category[],
  productsDetailsList: ProductsData['products']
): DynamicShowcase[] {
  return showcases.map((showcase) => {
    const { name, products } = showcase

    const productsWithDetails = products
      .map((product) => {
        const matchedProduct = productsDetailsList.find(
          ({ sku }) => product.sku === sku
        )

        if (matchedProduct) {
          return {
            ...matchedProduct,
            isRecommendation: product.isRecommendation,
          }
        }

        return null
      })
      .filter(Boolean) as DynamicShowcase['products']

    return {
      name,
      products: productsWithDetails,
    }
  })
}
